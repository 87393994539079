<template>

  <main id="main">

    <!-- ======= Breadcrumbs ======= -->
    <section id="breadcrumbs" class="breadcrumbs">
      <div class="breadcrumb-hero">
        <div class="container">
          <div class="breadcrumb-hero">
            <h2>Rock</h2>
          </div>
        </div>
      </div>
      <div class="container">
        <ol>
          <li><router-link :to="{ name: 'home' }">Home</router-link></li>
          <li>Products</li>
          <li>Rock</li>
        </ol>
      </div>
    </section><!-- End Breadcrumbs -->

    <!-- ======= Product Section ======= -->
    <section id="products" class="product">
      <div class="container">

        <div class="row">
          <product>
            <template v-slot:img>
              <a href="/img/rock/Shot_Rock.jpg" target="_blank">
                <img src="@/assets/img/rock/th_Shot_Rock.jpg" class="img-fluid" alt="">
              </a>
            </template>
            <template v-slot:name>
              Shot Rock
            </template>
            <template v-slot:description>
              Shot Rock is the unprocessed source rock utilised by the quarry's crushing plant. It is formed by drilling and blasting the basalt deposit
              and is usually supplied from the freshest and bluest rock unless otherwise specified. It typically ranges in size from 700mm down to fines
              though due to the nature of its production can range upwards depending on the blasting parameters used on each specific shot.
              Typically large boulders, e.g. 1 meter plus, are removed however.<br/>
              <br/>
              We encourage customers considering purchasing shot rock to visit the quarry and view the product for themselves before making a decision on this product.
              Call the quarry on (07) 5464 4644 to arrange a suitable time.<br/>
              <br/>
              <b>Standards</b><br/>
              None<br/>
              <br/>
              <b>Applications</b><br/>
              Landscaping & Decorative, Erosion Control
            </template>
          </product>

          <product>
            <template v-slot:img>
              <a href="/img/rock/Boulder.jpg" target="_blank">
                <img src="@/assets/img/rock/th_Boulder.jpg" class="img-fluid" alt="">
              </a>
            </template>
            <template v-slot:name>
              Boulders
            </template>
            <template v-slot:description>
              Boulders are rocks source from our shots which are not broken down by the blasting process sufficiently to be processed through the crushing plant.
              Typically they exceed 1 meter in size and will require machinery to move due to their significant weight.<br/>
              <br/>
              <b>Standards</b><br/>
              None<br/>
              <br/>
              <b>Applications</b><br/>
              Landscaping & Decorative, Erosion Control
            </template>
          </product>

          <product>
            <template v-slot:img>
              <a href="/img/rock/Rip_Rap.jpg" target="_blank">
                <img src="@/assets/img/rock/th_Rip_Rap.jpg" class="img-fluid" alt="">
              </a>
            </template>
            <template v-slot:name>
              350-150mm Rip Rap
            </template>
            <template v-slot:description>
              Rip Rap is rock that is screened and sorted into a more controlled size.<br/>
              <br/>
              <b>Standards</b><br/>
              None<br/>
              <br/>
              <b>Applications</b><br/>
              Rock Armor, Drainage, Soft ground, Erosion Control, Settlement Ponds, Bank Reinforcement, Gabion or Rock Baskets
            </template>
          </product>

          <product>
            <template v-slot:img>
              <a href="/img/rock/150-70.jpg" target="_blank">
                <img src="@/assets/img/rock/th_150-70.jpg" class="img-fluid" alt="">
              </a>
            </template>
            <template v-slot:name>
              150-70mm Drainage
            </template>
            <template v-slot:description>
              <br/>
              <b>Standards</b><br/>
              None<br/>
              <br/>
              <b>Applications</b><br/>
              Drainage, Erosion Control, Gabion or Rock Baskets
            </template>
          </product>

          <product>
            <template v-slot:img>
              <a href="/img/rock/53-20.jpg" target="_blank">
                <img src="@/assets/img/rock/th_53-20.jpg" class="img-fluid" alt="">
              </a>
            </template>
            <template v-slot:name>
              53-20mm Rundown
            </template>
            <template v-slot:description>
              53-20mm Rundown is produced using the same process as rail ballast though is produced from browner
              source rock and undergoes less rigorous quality control processes when compared to rail ballast.<br/>
              <br/>
              <b>Standards</b><br/>
              None<br/>
              <br/>
              <b>Applications</b><br/>
              Drainage, Soft Ground, Hard Fill, Retaining Wall Backfill
            </template>
          </product>

        </div>

      </div>
    </section><!-- End Product Section -->
  </main>
</template>
<script>
import Product from '@/components/Product.vue';

export default {
  components: {
    Product,
  },
};
</script>
