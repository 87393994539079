<template>
  <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
    <div class="product">
      <div class="product-img">
        <slot name="img"></slot>
      </div>
      <div class="product-info">
        <h4><slot name="name"></slot></h4>
        <slot name="description"></slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['image'],
};
</script>
<style scoped>
.product {
    padding: 30px;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0 2px 29px 0 rgba(68, 88, 144, 0.12);
    transition: all 0.3s ease-in-out;
    margin-bottom: 20px;
}
</style>
